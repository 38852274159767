const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function getTaskAssignments(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getTaskAssignments/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the report data.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getReportAvailability(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getReportAvailability/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not determine report availability.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getTeamMembers(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getTeamMembers/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the team members.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getTopDownReports(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getTopDownReports/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the report data.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getDailyStatus(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getDailyStatus/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the daily status data.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function updateDailyStatus(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/updateDailyStatus/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not update the daily status.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getDailyStatusReports(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getDailyStatusReports/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the daily status reports.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getProjectSchedule(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getProjectSchedule/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the project schedule data.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getProjectNotes(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/getProjectNotes/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the notes for the project.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function createProjectNote(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/createProjectNote/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not create the note for the project.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function removeProjectNote(pageData) {
	const response = await fetch(`${API_BASE_URL}/workamajig/removeProjectNote/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not remove the note for the project.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

