/**
***************************************************************************
* NAME 			: TeamMembers
* PROPS	 		: members (array) (required)
*				: (all members of the team excluding the requestor)
*				: - employeeID
*				: - firstName
*				: - lastName
*				: - name (full name)
*				: - teamId
*				: - team
*				: - supervisorEmployeeID
*				: - dailyStatusInd
*				: - dailyStatusCompletedInd
*				: - projectScheduleInd
* RETURNS 		: Listing of employees to access Workamajig reports
* LAST MODIFIED 	: July 19, 2023
***************************************************************************
*/

import EmployeeReportCard from '../../UI/EmployeeReportCard';

const TeamMembers = (props) => {
	return (
		<>
			<div className="outer-flexwrap">
				<div className="pageitem">
					<h3>my team members</h3>
					<div className="flexwrap_empcards">
						{
							props.members.map(employee => (
								<EmployeeReportCard
									key={employee.employeeId}
									id={employee.employeeId}
									firstName={employee.firstName}
									lastName={employee.lastName}
									dailyStatusInd={employee.dailyStatusInd}
									dailyStatusSubmittedInd={employee.dailyStatusSubmittedInd}
									projectScheduleInd={employee.projectScheduleInd}
								/>
							))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default TeamMembers;
