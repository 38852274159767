/**
***************************************************************************
* NAME 			: EmployeeReportCard
* PROPS	 		: children (employee name)
*				: id (employeeId)
*				: firstName (of employee)
*				: lastName (of employee)
*				: dailyStatusInd (1 || 0)
*				: dailyStatusSubmittedInd (1 || 0)
*				: projectScheduleInd (1 || 0)
*		 		: width (optional - default 117)
*		 		: height (optional - default 85)
* RETURNS 		: Styled link to direct to employee details
* LAST MODIFIED 	: June 8, 2023
***************************************************************************
*/

import { Link } from 'react-router-dom';
// import classes from './OrangeCard.module.css';

const EmployeeReportCard = (props) => {
//	const API_URL = "https://qxwuuqemnacfhweocuzr.mlinc.com";
	const name = props.firstName.toLowerCase() + ' ' + props.lastName.toLowerCase();
	const firstInitial = props.firstName.charAt(0);
	const headshotimgsrc = '/img/headshots/' + firstInitial.toLowerCase() + props.lastName.toLowerCase() + '.jpg';
	const dailyStatusURL = 'daily-status/' + props.id;
	const projectScheduleURL = 'project-schedule/' + props.id;

	return (
		<div className="emp-reportcard">
			<div className="headshot">
				<img src={headshotimgsrc} alt={name} width={props.width || '117'} height={props.height || '85'} />
			</div>
			<div className="info">
				<p className="name">{name}</p>

				{ (props.dailyStatusInd == 1) && (props.dailyStatusSubmittedInd != 1) &&
					<Link to={dailyStatusURL} className="button status not-submitted">
						daily status
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="none" d="M0 0h20v20H0z"/><path d="M19.288 18.833H.712A.71.71 0 01.1 17.768L9.4 1.524a.711.711 0 01.617-.357.712.712 0 01.617.358l9.274 16.244a.712.712 0 01-.618 1.064zM1.939 17.41h16.124l-8.05-14.1zM10 14.251a.6.6 0 01-.6-.6V7.845a.6.6 0 01.6-.6.6.6 0 01.6.6v5.806a.6.6 0 01-.6.6zm0 .827a.6.6 0 00-.6.6.6.6 0 00.6.6.6.6 0 00.6-.6.6.6 0 00-.6-.6z" className="shape" fill="#ff0010"/></svg>
					</Link>
				}

				{ (props.dailyStatusInd == 1) && (props.dailyStatusSubmittedInd == 1) &&
					<Link to={dailyStatusURL} className="button status viewed">
						daily status
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.2088 2.79988 7.35233 13.6575 1.79115 8.09518-.00061 9.88812l6.46706 6.46468c.48823.4883 1.28588.4906 1.77294 0L19.9994 4.59047l-1.7906-1.79059Z" class="shape" fill="#005BF3"/></svg>
					</Link>
				}

				{ (props.projectScheduleInd == 1) &&
					<Link to={projectScheduleURL} className="button schedule">
						project schedule
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4 14.8027H3.99999c-.11045 0-.2.0908-.2.2028v2.0277c0 .112.08955.2028.2.2028H18.4c.1105 0 .2-.0908.2-.2028v-2.0277c0-.112-.0895-.2028-.2-.2028ZM3.99999 13.3832c-.88365 0-1.6.7263-1.6 1.6223v2.0277c0 .8959.71635 1.6222 1.6 1.6222H18.4c.8836 0 1.6-.7263 1.6-1.6222v-2.0277c0-.896-.7163-1.6223-1.6-1.6223H3.99999ZM14.8 8.71938H1.6c-.11046 0-.2.09078-.2.20277v2.02775c0 .112.08954.2028.2.2028h13.2c.1105 0 .2-.0908.2-.2028V8.92215c0-.11199-.0895-.20277-.2-.20277ZM1.6 7.29994c-.883656 0-1.6.72629-1.6 1.62221v2.02775c0 .8959.716345 1.6222 1.6 1.6222h13.2c.8837 0 1.6-.7263 1.6-1.6222V8.92215c0-.89592-.7163-1.62221-1.6-1.62221H1.6ZM16 2.63609H8.80001c-.11045 0-.2.09079-.2.20278v2.02776c0 .11199.08955.20278.2.20278H16c.1105 0 .2-.09079.2-.20278V2.83887c0-.11199-.0895-.20278-.2-.20278ZM8.80001 1.21666c-.88365 0-1.6.72629-1.6 1.62221v2.02776c0 .89593.71635 1.62221 1.6 1.62221H16c.8837 0 1.6-.72628 1.6-1.62221V2.83887c0-.89592-.7163-1.62221-1.6-1.62221H8.80001Z" class="shape" fill="#005BF3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.7 19.8721c-.3866 0-.7-.3178-.7-.7097V.709715c0-.391966.3134-.709717.7-.709717.3866 0 .7.317751.7.709717V19.1624c0 .3919-.3134.7097-.7.7097Z" class="shape" fill="#005BF3"/></svg>
					</Link>
}
			</div>
		</div>
	)
}

export default EmployeeReportCard;