import { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/auth-context';
import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import { getEmployeeName, getRole, checkGroupMember } from '../api/employee';
import { getReportAvailability, getTeamMembers } from '../api/reports';
// import { getReportAvailability, getTopDownReports } from '../api/reports';
import NavigationCallouts from '../components/containers/home/NavigationCallouts';
import TeamMembers from '../components/containers/home/TeamMembers';
// import TopDownReports from '../components/containers/home/TopDownReports';
import DailyStatusDashboard from '../components/containers/home/DailyStatusDashboard';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const Home = () => {
	// Set header information and body classes
	document.title = "MLCentral Annex | Powered by Media Logic";
	document.body.className = "home";

	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [employeeInfo, setEmployeeInfo] = useState([]);
	const [role, setRole] = useState('');
	const [tmMember, setTMMember] = useState(true);
	const [teamMembers, setTeamMembers] = useState([]);
	const [alertClass, setAlertClass] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const [dailyStatusInd, setDailyStatusInd] = useState(true);
	const [projectScheduleInd, setProjectScheduleInd] = useState(true);
	// const [reports, setReports] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isTeamsLoading, setIsTeamsLoading] = useState(true);
	// const [isReportsLoading, setIsReportsLoading] = useState(true);

	const requestData = {
		token: authCtx.token
	};
	const tmRequestData = {
		token: authCtx.token,
		group: 'Traffic Management'
	};

	const { sendRequest, status, data, error } = useHttp(getEmployeeName, true);
	const { sendRequest: roleRequest, status: roleStatus, data: roleData, error: roleError } = useHttp(getRole, true);
	const { sendRequest: tmRequest, status: tmStatus, data: tmData, error: tmError } = useHttp(checkGroupMember, true);
	const { sendRequest: teamRequest, status: teamStatus, data: teamData, error: teamError } = useHttp(getTeamMembers, true);
	const { sendRequest: availabilityRequest, status: availabilityStatus, data: availabilityData, error: availabilityError } = useHttp(getReportAvailability, true);
//	const { sendRequest: tdRequest, status: tdStatus, data: tdData, error: tdError } = useHttp(getTopDownReports, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					error
				)
			} else if (!data['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					data['status_code'] + ': ' + data['status']
				)
			} else {
				// console.log('EMPLOYEE DATA');
				// console.log(data['data']);

				setEmployeeInfo(data['data']);
			}

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [status, error, data]);

	useEffect(() => {
		if (roleStatus === 'completed') {
			if (roleError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					roleError
				)
			} else if (!roleData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					roleData['status_code'] + ': ' + roleData['status']
				)
			} else {
				// console.log('ROLE');
				// console.log(roleData['data']['role']);

				setRole(roleData['data']['role']);
			}
		}
	}, [roleStatus, roleError, roleData]);

	useEffect(() => {
		if (tmStatus === 'completed') {
			if (tmError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					tmError
				)
			} else if (!tmData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					tmData['status_code'] + ': ' + tmData['status']
				)
			} else {
				// console.log('GROUP MEMBER DATA');
				// console.log(tmData['data']);

				setTMMember(tmData['data']['member']);
				// setIsLoading(false);
			}
		}
	}, [tmStatus, tmError, tmData]);

	useEffect(() => {
		if (teamStatus === 'completed') {
			if (teamError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					teamError
				)
			} else if (!teamData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					teamData['status_code'] + ': ' + teamData['status']
				)
			} else {
				// console.log('TEAM MEMBER DATA');
				// console.log(teamData['data']);

				setTeamMembers(teamData['data']);
				setIsTeamsLoading(false);
			}
		}
	}, [teamStatus, teamError, teamData]);

	useEffect(() => {
		if (availabilityStatus === 'completed') {
			if (availabilityError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					availabilityError
				)
			} else if (!availabilityData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					availabilityData['status_code'] + ': ' + availabilityData['status']
				)
			} else {
				// console.log('AVAILABILITY DATA');
				// console.log(availabilityData['data']);

				if (!availabilityData['data']['dailyStatusInd']) {
					setDailyStatusInd(false);
				} else if (!availabilityData['data']['dailyStatusSubmittedInd']) {
					setAlertClass("status-alert not-submitted");
					setAlertMessage("please submit your daily status");
				}
				if (!availabilityData['data']['projectScheduleInd']) {
					setProjectScheduleInd(false);
				}
			}

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [availabilityStatus, availabilityError, availabilityData]);

	/*
	useEffect(() => {
		if (tdStatus === 'completed') {
			if (tdError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					tdError
				)
			} else if (!tdData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					tdData['status_code'] + ': ' + tdData['status']
				)
			} else {
				// console.log('REPORTS DATA');
				// console.log(tdData['data']);

				setReports(tdData['data']);
				setIsReportsLoading(false);
			}
		}
	}, [tdStatus, tdError, tdData]);
	*/

	useEffect(() => {
		sendRequest(requestData);
		roleRequest(requestData);
		tmRequest(tmRequestData);
		teamRequest(requestData);
		availabilityRequest(requestData);
//		tdRequest(requestData);
	}, [sendRequest, roleRequest, tmRequest, teamRequest, availabilityRequest]);

	useEffect(() => {
		if (
			(status === 'completed') &&
			(roleStatus === 'completed') &&
			(tmStatus === 'completed') &&
			// (teamStatus === 'completed') &&
			(availabilityStatus === 'completed') 
		) {
			setIsLoading(false);
		}
	}, [status, roleStatus, tmStatus, availabilityStatus]);

	return (
		<>
			<header role="banner">
				<div className="constrained">
					<h1><span className="wavehello" {...!isLoading && {style : {animationPlayState: 'running'}}}>&#x1F44B;</span> {employeeInfo.firstName}</h1>
				</div>
			</header>

			<main>
				<div className="constrained">
					{
						isLoading && (
							<div>
								<LoadingSpinner />
							</div>
						)
					}
					{
						!isLoading && (
							<>
								{ (alertMessage != "") &&
									<p className={alertClass}>{alertMessage}</p>
								}
								{ !tmMember && (role != 'President') && (role != 'Administrator') && !dailyStatusInd && !projectScheduleInd && !isTeamsLoading && (teamMembers.length == 0) &&
									<div className="outer-flexwrap">
										You do not have reports to review.
									</div>
								}
								{ !tmMember && (role != 'President') && (role != 'Administrator') && (dailyStatusInd || projectScheduleInd) &&
									<div className="outer-flexwrap">
										<NavigationCallouts
											dailyStatusInd = {dailyStatusInd}
											projectScheduleInd = {projectScheduleInd}
										/>
									</div>
								}
								{ !tmMember && (role != 'President') && (role != 'Administrator') && !isTeamsLoading && (teamMembers.length > 0) &&
									<>
										<TeamMembers members={teamMembers} />
									</>
								}
								{
									/*
									!tmMember && (role != 'Administrator') && !isReportsLoading && (reports.length > 0) &&
									<>
										<TopDownReports reports={reports} />
									</>
									*/
								}
								{ (tmMember || (role == 'President') || (role == 'Administrator')) &&
									<>
										<h2>daily status</h2>
										<DailyStatusDashboard />
									</>
								}
							</>
						)
					}
				</div>
			</main>
		</>
	)
}

export default Home;