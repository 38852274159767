/**
***************************************************************************
* NAME 			: DirectContent
* PURPOSE			: Calls content data without checking authentication.
*				: Once the data is obtained, it's passed to a container
*				: for display and/or passed back to the calling container.
* PROPS	 		: url (URL of the API call)
*				: container (container to pass data to for display on success) - optional
*				: dataReturn (returns the data to the calling container on success) - optional
*				: resettimeout (true || false) (resets the timer for the access token) - optional
* RETURNS 		: Container with data on success (if props.container passed)
*		 		: AND/OR data on success (if props.returnData passed)
* HTTP RETURNS		: success (true || false)
				: status_code (200, 400, 500, etc.)
				: status (OK, Request Failed, etc.)
				: data (result set in an array)
* LAST MODIFIED 	: July 31, 2021
***************************************************************************
*/

import { useContext, useEffect } from 'react';
import AuthContext from '../../../context/auth-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useHttp from '../../../hooks/use-http';
import { getDirectContent } from '../../../api/content';

// import DirectContainer from '../test/DirectContainer';

let contentData;

const DirectContent = (props) => {
	const authCtx = useContext(AuthContext);

	const { sendRequest, status, data, error } = useHttp(getDirectContent, true);

	useEffect(() => {
		const page = {
			token: authCtx.token,
			url: props.url
		};

		sendRequest(page);
	}, [sendRequest]);

	if (status === 'pending') {
		return (
			<div><LoadingSpinner /></div>
		);
	} else if (error !== null) {
		return <p>Error: {error}</p>
	} else if (status === 'completed') {
// console.log('CONTENT DATA');
// console.log(data);
		if (!data['data']['access']) {
			// The visitor shouldn't be in here.
			// This is to catch users manually adjusting tokens or a failure to timeout on the frontend.
// console.log('DATA ACCESS FALSE');
// console.log(data);
			if (!data['token']['access']) {
//				console.log('ACCESS TOKEN INVALID:');
				authCtx.logout();
			} else if (!data['token']['session']) {
//				console.log('SESSION TOKEN INVALID:');
				authCtx.logout();
			} else if (!data['token']['browser']) {
//				console.log('BROWSER TOKEN INVALID:');
				authCtx.trustBrowser(false);
//				authCtx.logout();
			}
			return null;
		} else if (!data['success']) {
			return <p>{ data['status_code'] + ': ' + data['status'] }</p>
		} else if (!data['data']['content'] || data['data']['content'].length === 0) {
			return <p>No records found</p>
		} else {
			if ((typeof props.resettimeout === 'undefined') || (props.resettimeout === 'true')) {
				// Reset timeout for access token
				authCtx.updateExpiration();
// console.log('REFRESH FOR CONTENTD');
			}

			contentData = data['data']['content'];

			// Return the data to the calling container if specified
			if (typeof props.dataReturn !== 'undefined') {
				props.dataReturn(contentData);
			}
		}
	}

	return (
		<>
			{/* (props.container == 'DirectContainer') && <DirectContainer content={contentData} /> */}
		</>
	)
}

export default DirectContent;