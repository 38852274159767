import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom'; // Sets css class on the active anchor item
import AuthContext from '../../../context/auth-context';
import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getDailyStatusReports } from '../../../api/reports';
// import EmployeeCard from '../../UI/EmployeeCard';
import EmployeeReportCard from '../../UI/EmployeeReportCard';
import LoadingSpinner from '../../UI/LoadingSpinner';

const DailyStatusDashboard = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const pendingRequestData = {
		token: authCtx.token,
		status: 'Pending'
	};
	const submittedRequestData = {
		token: authCtx.token,
		status: 'Submitted'
	};
	// const completedRequestData = {
	// 	token: authCtx.token,
	// 	status: 'Completed'
	// };

	const [pendingEmployees, setPendingEmployees] = useState([]);
	const [submittedEmployees, setSubmittedEmployees] = useState([]);
	// const [completedEmployees, setCompletedEmployees] = useState([]);
	const [pendingIsLoading, setPendingIsLoading] = useState(true);
	const [submittedIsLoading, setSubmittedIsLoading] = useState(true);
	// const [completedIsLoading, setCompletedIsLoading] = useState(true);
	const [lastUpdated, setLastUpdated] = useState('');
	let url = 'daily-status/';

	const { sendRequest: pendingRequest, status: pendingStatus, data: pendingData, error: pendingError } = useHttp(getDailyStatusReports, true);
	const { sendRequest: submittedRequest, status: submittedStatus, data: submittedData, error: submittedError } = useHttp(getDailyStatusReports, true);
	// const { sendRequest: completedRequest, status: completedStatus, data: completedData, error: completedError } = useHttp(getDailyStatusReports, true);

	useEffect(() => {
		if (pendingStatus === 'completed') {
			if (pendingError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					pendingError
				)
			} else if (!pendingData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					pendingData['status_code'] + ': ' + pendingData['status']
				)
			} else {
				// console.log('PENDING DATA');
				// console.log(pendingData['data']);

				// setPageAccess(true);
				setPendingEmployees(pendingData['data']);
				setLastUpdated(pendingData['moment']);
			}
			setPendingIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [pendingStatus, pendingError, pendingData]);

	useEffect(() => {
		if (submittedStatus === 'completed') {
			if (submittedError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					submittedError
				)
			} else if (!submittedData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					submittedData['status_code'] + ': ' + submittedData['status']
				)
			} else {
				// console.log('SUBMITTED DATA');
				// console.log(submittedData['data']);

				// setPageAccess(true);
				setSubmittedEmployees(submittedData['data']);
				setLastUpdated(submittedData['moment']);
			}
			setSubmittedIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [submittedStatus, submittedError, submittedData]);

/*
	useEffect(() => {
		if (completedStatus === 'completed') {
			if (completedError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					completedError
				)
			} else if (!completedData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					completedData['status_code'] + ': ' + completedData['status']
				)
			} else {
				// console.log('COMPLETED DATA');
				// console.log(completedData['data']);
				// console.log('COMPLETED MOMENT');
				// console.log(completedData['moment']);

				// setPageAccess(true);
				setCompletedEmployees(completedData['data']);
				setLastUpdated(completedData['moment']);
			}
			setCompletedIsLoading(false);

			// Reset timeout
			// authCtx.updateExpiration();
		}
	}, [completedStatus, completedError, completedData]);
*/

	useEffect(() => {
		pendingRequest(pendingRequestData);
		submittedRequest(submittedRequestData);
		// completedRequest(completedRequestData);

		const interval = setInterval(() => {
			pendingRequest(pendingRequestData);
			submittedRequest(submittedRequestData);
			// completedRequest(completedRequestData);
		}, 60000); // 1 minute
		return () => clearInterval(interval);
	}, [pendingRequest, submittedRequest]);

	return (
		<>
			{
				(lastUpdated != '') && (
					<div className="last-updated">
						<i>Last updated at {lastUpdated}</i>
					</div>
				)
			}
			<div className="outer-flexwrap">
				<div className="pageitem">
					<h3>pending</h3>
					{
						pendingIsLoading && (
							<div>
								<LoadingSpinner />
							</div>
						)
					}
					{
						!pendingIsLoading && (
							<>
								{(pendingEmployees.length == 0) &&
									<div>No daily status reports are pending</div>
								}
	
								<div className="flexwrap_empcards">
									{
										pendingEmployees.map(employee => (
											<>
												<EmployeeReportCard
													key={employee.employeeId}
													id={employee.employeeId}
													firstName={employee.firstName}
													lastName={employee.lastName}
													dailyStatusInd="1"
													dailyStatusSubmittedInd="0"
													projectScheduleInd={employee.projectScheduleInd}
												/>
												{/*
												<EmployeeCard
													key={employee.employeeId}
													id={employee.employeeId}
													firstName={employee.firstName}
													lastName={employee.lastName}
													url={url}
												/>
												*/}
											</>
										))
									}
								</div>
							</>
						)
					}
				</div>
				<div className="pageitem">
					<h3>submitted</h3>
					{
						submittedIsLoading && (
							<div>
								<LoadingSpinner />
							</div>
						)
					}
					{
						!submittedIsLoading && (
							<>
								{(submittedEmployees.length == 0) &&
									<div>No daily status reports are submitted</div>
								}
	
								<div className="flexwrap_empcards">
									{
										submittedEmployees.map(employee => (
											<>
												<EmployeeReportCard
													key={employee.employeeId}
													id={employee.employeeId}
													firstName={employee.firstName}
													lastName={employee.lastName}
													dailyStatusInd="1"
													dailyStatusSubmittedInd="1"
													projectScheduleInd={employee.projectScheduleInd}
												/>
												{/*
												<EmployeeCard
													key={employee.employeeId}
													id={employee.employeeId}
													firstName={employee.firstName}
													lastName={employee.lastName}
													url={url}
												/>
												*/}
											</>
										))
									}
								</div>
							</>
						)
					}
				</div>
{/*
				<div className="pageitem">
					<h3>completed</h3>
					{
						completedIsLoading && (
							<div>
								<LoadingSpinner />
							</div>
						)
					}
					{
						!completedIsLoading && (
							<>
								{(completedEmployees.length == 0) &&
									<div>No daily status reports are completed</div>
								}
	
								<div className="flexwrap_empcards">
									{
										completedEmployees.map(employee => (
											<EmployeeCard
												key={employee.employeeId}
												id={employee.employeeId}
												firstName={employee.firstName}
												lastName={employee.lastName}
												url={url}
											/>
										))
									}
								</div>
							</>
						)
					}
				</div>
*/}
			</div>
		</>
	)
}

export default DailyStatusDashboard;
