import { useState, useContext, useEffect, useCallback } from 'react';
import AuthContext from '../../../context/auth-context'
import NotificationContext from '../../../context/notification-context';
import LoadingSpinner from '../../UI/LoadingSpinner';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';
import { checkPersonalPin } from '../../../api/authentication';
// import TextField from '@material-ui/core/TextField';

const PersonalPinForm = (props) => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);
	const [formProcessing, setFormProcessing] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const logoutTime = (1 * 60 * 60 * 1000); // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds

	const { sendRequest, status, data, error } = useHttp(checkPersonalPin);

	useEffect(() => {
		if (status === 'completed') {
			// console.log('DATA');
			// console.log(data);

			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"OK",
				// 	error
				// )
				setErrorMessage(error);
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// );
				setErrorMessage(data['status_code'] + ': ' + data['status']);
			} else if (data['data']['maxReached']) {
				// notifyCtx.displayNotification(
				// 	"WARNING",
				// 	data['data']['message']
				// );
				props.onMaxAttempts('You entered your personal PIN incorrectly three consecutive times when attempting to log in. For security reasons, your personal PIN has been removed. Please log in using the form below.');
				props.onPassword(3);
			} else if (!data['data']['access']) {
				setErrorMessage(data['data']['message']);
			} else {
				// Log In
				// console.log('LOG IN');
				// console.log(data['data']);

				const expiration = new Date(new Date().getTime() + logoutTime) // + 1 hour
				authCtx.login(data['data']['token'], expiration.toISOString());

				if (data['data']['browser']) {
					authCtx.trustBrowser(true);
				} else {
					props.onLogin(data['data']['message']);
				}
			}
		}
	}, [status, error, data, authCtx]);

	// Requirement types
	const isPin = value => (value.match(/^\d+$/) && value.length >= 4 && value.length <= 6); // Positive number with a length of 4-6

	const {
		value: enteredPin,
		isValid: pinIsValid,
		hasError: pinHasError,
		valueChangeHandler: pinChangedHandler,
		inputBlurHandler: pinBlurHandler,
		valueSubmitHandler: pinSubmitHandler,
		reset: resetPin
	} = useInput(isPin); // value to be evaluated => Function defined inline passed to useInput (determines if value is valid)

	let formIsValid = false;
	if (pinIsValid) { // And any other form fields
		formIsValid = true;
	}

	const submitHandler = event => {
		event.preventDefault();

		setFormProcessing(true);

		// Added (if form is able to be submitted first before error checking)
 		pinSubmitHandler();

		if (!formIsValid) {
			setErrorMessage(null);
			setFormProcessing(false);
			return;
		}

		const formData = {
			pin: enteredPin,
			via: "annex"
		};

		sendRequest(formData);

		// Reset values
		resetPin();
		
		setFormProcessing(false);
	}

	const userHandler = () => {
		props.onPassword(3);
	}

	const passwordHandler = (attempts) => {
		props.onPassword(0);
	}

	const codeInputClasses = pinHasError ? 'form-control invalid' : 'form-control';

	return (
		<div className="formwrap">
			<h2>welcome back, {props.firstName.toLowerCase()}!</h2>
			{/*
				{
					<p className="instructions">{initMessage}</p>
				}
			*/}
			{
				<p className="error-text">{errorMessage}</p>
			}
			<form onSubmit={submitHandler}>
				<div className={codeInputClasses}>
					<label htmlFor='pin'>personal PIN</label>
					<input
						type="password"
						name="pin"
						id="pin"
						onChange={pinChangedHandler}
						onBlur={pinBlurHandler}
						value={enteredPin}
					/>
					{pinHasError &&
						// <p className="error-text">Email must include @.</p>
						<p className="error-text">Valid PIN required.</p>
					}
				</div>

				<div className="button-wrap">
					<div>
						<p><a onClick={userHandler}>not {props.firstName.toLowerCase()}?</a></p>
						<p><a onClick={passwordHandler}>use password</a></p>
						
					</div>
					{ formProcessing &&
						<div>
							<LoadingSpinner />
						</div>
					}
					{ !formProcessing &&
						<button type="submit" disabled={formProcessing}>log in</button>
					}
				</div>
			</form>
		</div>
	)
}

export default PersonalPinForm;