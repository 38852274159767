/**
***************************************************************************
* NAME 			: NavigationCallouts
* PROPS	 		: dailyStatusInd (boolean) (required)
*				: projectScheduleInd (boolean) (required)
* RETURNS 		: Listing of reports available to access
* LAST MODIFIED 	: June 1, 2023
***************************************************************************
*/

import LinkCard from '../../UI/LinkCard';
// import classes from './NavigationCallouts.module.css';

const NavigationCallouts = (props) => {
	return (
		<div className="pageitem column links">
			<ul className="mainnav_links">
				{
					props.dailyStatusInd &&
					<li><LinkCard to="/daily-status/" img="daily-status-icn-white.svg">daily status</LinkCard></li>
				}
				{
					props.projectScheduleInd &&
					<li><LinkCard to="/project-schedule/" img="project-sched-icn-white.svg">project schedule</LinkCard></li>
				}
			</ul>
		</div>
	)
}

export default NavigationCallouts;
