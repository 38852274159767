import { useState, useEffect, useContext } from 'react';
// import NotificationContext from '../context/notification-context';
import useHttp from '../hooks/use-http';
import { getYear } from '../api/footer';

const Footer = () => {
//	const notifyCtx = useContext(NotificationContext);

	const [year, setYear] = useState(null);

	const { sendRequest, status, data, error } = useHttp(getYear, true);

	useEffect(() => {
		if (status === 'completed') {
			if (error !== null) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	error
				// )
			} else if (!data['success']) {
				// notifyCtx.displayNotification(
				// 	"ERROR",
				// 	data['status_code'] + ': ' + data['status']
				// )
			} else {
				setYear(data['data']);
			}
		}
	}, [status, error, data]);

	useEffect(() => {
		sendRequest();
	}, [sendRequest]);

	return (
		<footer>
			<div className="constrained">
				<div className="flex-wrap">
					<div className="logo"><img src="/img/medialogic-logo.svg" alt="Media Logic" width="112" height="67" /></div>
					<div className="applinks">
						<ul>
							<li><a href="https://mlcentral.workamajig.com/platinum/" target="_blank" rel="noreferrer"><img src="/img/workamajig-icn.svg" alt="" width="33" height="33" />MLCentral</a></li>
							<li><a href="https://tot.mlinc.com" target="_blank" rel="noreferrer"><img src="/img/tot-icn.svg" alt="" width="33" height="33" />Time Off Tracker</a></li>
							<li><a href="https://www.mlcontenthub.com/" target="_blank" rel="noreferrer"><img src="/img/mlcontenthub-icn.svg" alt="" width="33" height="33" />MLContentHub</a></li>
							<li><a href="https://analytics.google.com/analytics/web/provision/"><img src="/img/datasnap-icn.svg" alt="" width="33" height="33" />DataSnap</a></li>
							<li><a href="http://romicheck.medialogic.com/"><img src="/img/romicheck-icn.svg" alt="RomiCheck" width="33" height="33" />RomiCheck</a></li>
							<li><a href="https://app.trakstar.com/" target="_blank" rel="noreferrer"><img src="/img/trakstar-icn.svg" alt="" width="33" height="33" />Trakstar</a></li>
							<li><a href="https://outlook.office.com/" target="_blank" rel="noreferrer"><img src="/img/webmail-icn.svg" alt="" width="33" height="33" />Webmail</a></li>
						</ul>
					</div>
					<p className="copyright">&copy;{year} Media Logic</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer;