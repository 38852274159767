import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import DirectContent from '../data/DirectContent';
import DateTime from './DateTime';
import Weather from './Weather';

const Topper = () => {
	const authCtx = useContext(AuthContext);

	const [dateTimeData, setdateTimeData] = useState(null);

	const dateTimeHandler = (data) => {
		setdateTimeData(data);
	}

	return (
		<div className="topper">
			<div className="constrained">
				<div className="flex-wrap">
					<div className="annexlogo">
						{(!authCtx.isLoggedIn || !authCtx.trustedBrowser) && <img src="/img/mlcentral-annex-logo-white.svg" alt="mlannex" width="150" height="85" /> }
						{authCtx.isLoggedIn && authCtx.trustedBrowser && <Link to="/"><img src="/img/mlcentral-annex-logo.svg" alt="mlannex" width="150" height="85" /></Link>}
					</div>
					
					<div className="daily-info">
						{ authCtx.isLoggedIn && authCtx.trustedBrowser && <DirectContent url="header/getDateTime/" dataReturn={dateTimeHandler} resettimeout="false" /> }
						{ authCtx.isLoggedIn && authCtx.trustedBrowser && (dateTimeData != null) && <DateTime content={dateTimeData} /> }
						{ authCtx.isLoggedIn && authCtx.trustedBrowser && (dateTimeData != null) && <Weather content={dateTimeData} /> }
					</div>
				</div>
			</div>
		</div>
	)
}

export default Topper;