import React, { useContext, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthContext from './context/auth-context'
import LoadingSpinner from './components/UI/LoadingSpinner';
import NotificationBar from './components/UI/NotificationBar';

import Header from './includes/Header';
import Footer from './includes/Footer';

// Pages
import Home from './pages/Index';
import Login from './pages/Login';

// Lazy Loaded Imports (must come after regular imports)
const DailyStatus = React.lazy(() => import('./pages/daily-status/Index'));
const ProjectSchedule = React.lazy(() => import('./pages/project-schedule/Index'));
const Account = React.lazy(() => import('./pages/account/Index'));
const Weather = React.lazy(() => import('./pages/weather/Index'));

function App() {
	const authCtx = useContext(AuthContext);

	return (
		<>
			<Suspense
				fallback = {
					<div className='centered'>
						<LoadingSpinner />
					</div>
				}
			>
				{authCtx.isLoggedIn && authCtx.trustedBrowser &&
					<NotificationBar />
				}
				<Header />

				{(!authCtx.isLoggedIn || !authCtx.trustedBrowser) && <Login />}
				{authCtx.isLoggedIn && authCtx.trustedBrowser &&
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path='/daily-status/' element={<DailyStatus />} />
						<Route path="/daily-status/:employeeId" element={<DailyStatus />} />
						<Route path='/project-schedule/' element={<ProjectSchedule />} />
						<Route path='/project-schedule/:employeeId' element={<ProjectSchedule />} />
						<Route path='/account/' element={<Account />} />
						<Route path='/weather/' element={<Weather />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				}

				<Footer />
			</Suspense>
		</>
	);
}

export default App;
