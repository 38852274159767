const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;
 
export async function getDirectReports(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getDirectReports/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the direct reports.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getRole(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getRole/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the role of the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function checkGroupMember(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/checkGroupMember/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not confirm group membership of the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function checkTeamMember(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/checkTeamMember/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not confirm team membership of the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getSupervisor(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getSupervisor/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the supervisor.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getEmployees(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getEmployees/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the employees.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getEmployeeName(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getEmployeeName/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the first name for the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getEmployeeInfo(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getEmployeeInfo/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the information for the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getEmployeeOverview(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getEmployeeOverview/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the details for the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getEmployeeDetails(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getEmployeeDetails/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the details for the employee.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getStates(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getStates/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the states.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getTeams(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getTeams/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the teams.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getDepartments(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getDepartments/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the departments.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}

export async function getTimeZones(pageData) {
	const response = await fetch(`${API_BASE_URL}/employee/getTimeZones/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
		// headers: {
		// 	'Content-Type': 'application/json'
		// },
	});

	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not get the time zones.');
	}

	const data = await response.json();

	if (!data['success']) { // Manually created and returned attributes
		throw new Error(data['status_code'] + ': ' + data['status']);
	}
	// console.log('DATA');
	// console.log(data);
	return data;
}
