 const API_BASE_URL = process.env.REACT_APP_PHP_API_BASE_URL;

export async function getYear(pageData) {
	// console.log('PAGEDATA:');
	// console.log(pageData);
	const response = await fetch(`${API_BASE_URL}/footer/getYear/`, {
		method: 'POST',
		body: JSON.stringify(pageData),
		credentials: 'include'
	});

	// Check for unsuccessful response
	if (!response.ok) {
		throw new Error(response.status + ': ' + response.statusText + ': Could not retrieve the year.');
	}

	const data = await response.json();

	return data;
}