import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom'; // Sets css class on the active anchor item
 import AuthContext from '../../../context/auth-context';
 import NotificationContext from '../../../context/notification-context';
import useHttp from '../../../hooks/use-http';
import { getReportAvailability } from '../../../api/reports';

import classes from './Navigation.module.css';

const Navigation = () => {
	const authCtx = useContext(AuthContext);
	const notifyCtx = useContext(NotificationContext);

	const [dailyStatusInd, setDailyStatusInd] = useState(false);
	const [projectScheduleInd, setProjectScheduleInd] = useState(false);
	const [navClass, setNavClass] = useState('menu-icon');
	const [ariaExpanded, setAriaExpanded] = useState('false');

	const { sendRequest: availabilityRequest, status: availabilityStatus, data: availabilityData, error: availabilityError } = useHttp(getReportAvailability, true);

	/**
	**********
	INIT
	**********
	*/
	useEffect(() => {
		if (availabilityStatus === 'completed') {
			if (availabilityError !== null) {
				notifyCtx.displayNotification(
					"ERROR",
					availabilityError
				)
			} else if (!availabilityData['success']) {
				notifyCtx.displayNotification(
					"ERROR",
					availabilityData['status_code'] + ': ' + availabilityData['status']
				)
			} else {
				// console.log('AVAILABILITY DATA');
				// console.log(availabilityData['data']);

				if (availabilityData['data']['dailyStatusInd']) {
					setDailyStatusInd(true);
				}
				if (availabilityData['data']['projectScheduleInd']) {
					setProjectScheduleInd(true);
				}
			}

			// Reset timeout
			authCtx.updateExpiration();
		}
	}, [availabilityStatus, availabilityError, availabilityData]);

	useEffect(() => {
		if ((typeof authCtx.token !== 'undefined') && (authCtx.trustedBrowser)) {
			if (authCtx.token !== null) {
				const requestData = {
					token: authCtx.token
				};
				// console.log('REQUEST DATA');
				// console.log(requestData);

				availabilityRequest(requestData);
			}
		}
	}, [availabilityRequest, authCtx.token, authCtx.trustedBrowser]);

	/**
	**********
	HANDLERS
	**********
	*/
	const navigationHandler = () => {
		if (navClass != 'menu-icon open') {
			setNavClass('menu-icon open');
			setAriaExpanded('true');
		} else {
			setNavClass('menu-icon close');
			setAriaExpanded('false');
		}
	};

	const logoutHandler = () => {
		setNavClass('menu-icon close');
		setAriaExpanded('false');
		authCtx.logout();
	};

	return (
		<div className="navigation-wrap">
			<button className={navClass} aria-controls="navbar" aria-expanded={ariaExpanded} onClick={navigationHandler}>
				<span className="burger-layer"></span>
				<span className="burger-layer"></span>
				<span className="burger-layer"></span>
				<span className="text">menu</span>
			</button>
			<nav id="navbar">
				<ul>
					{ dailyStatusInd &&
						<li>
							<NavLink to="/daily-status/" className={(navData) => navData.isActive ? classes.active : ''} onClick={navigationHandler}>
								<div className="icn hrstuff">
									<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><g data-name="Layer 1"><path d="M39.179 36.82c0 1.143-.929 2.072-2.072 2.072H4.893c-1.143 0-2.072-.929-2.072-2.072V16.464h36.358V36.82ZM10.117 10.369c.778 0 1.411-.632 1.411-1.41V6.016h18.945v2.943c0 .778.633 1.41 1.411 1.41s1.41-.632 1.41-1.41V6.016h3.814c1.143 0 2.072.929 2.072 2.072v5.555H2.821V8.088c0-1.143.929-2.072 2.072-2.072h3.814v2.943c0 .778.633 1.41 1.41 1.41Zm26.99-7.174h-3.814V.288h-2.821v2.908H11.528V.288H8.707v2.908H4.893C2.195 3.195 0 5.389 0 8.088v28.731c0 2.699 2.195 4.893 4.893 4.893h32.214c2.698 0 4.893-2.194 4.893-4.893V8.088c0-2.699-2.195-4.893-4.893-4.893ZM28.51 19.607 18.023 30.09l-5.372-5.371-1.731 1.731 6.247 6.242c.472.471 1.242.474 1.713 0l11.36-11.357-1.73-1.729Z" fill="#fff" fill-rule="evenodd" className="theicon"/><path fill="none" d="M0 0h42v42H0z"/></g></svg>
								</div><br />
								daily status
							</NavLink>
						</li>
					}
					{ projectScheduleInd &&
						<li>
							<NavLink to="/project-schedule/" className={(navData) => navData.isActive ? classes.active : ''} onClick={navigationHandler}>
								<div className="icn hrstuff">
									<svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><g data-name="Layer 1"><path d="M38.64 28.14h-25.2v-1.68h17.64c1.856 0 3.36-1.504 3.36-3.36v-4.2c0-1.856-1.504-3.36-3.36-3.36H13.44V1.89c0-.812-.658-1.47-1.47-1.47s-1.47.658-1.47 1.47v13.65H3.36C1.504 15.54 0 17.044 0 18.9v4.2c0 1.856 1.504 3.36 3.36 3.36h7.14v1.68H8.4c-1.856 0-3.36 1.504-3.36 3.36v4.2c0 1.856 1.504 3.36 3.36 3.36h2.1v1.05c0 .812.658 1.47 1.47 1.47s1.47-.658 1.47-1.47v-1.05h25.2c1.856 0 3.36-1.504 3.36-3.36v-4.2c0-1.856-1.504-3.36-3.36-3.36Zm-7.56-9.66c.232 0 .42.188.42.42v4.2c0 .232-.188.42-.42.42H13.44v-5.04h17.64ZM3.36 23.52c-.232 0-.42-.188-.42-.42v-4.2c0-.232.188-.42.42-.42h7.14v5.04H3.36Zm5.04 12.6c-.232 0-.42-.188-.42-.42v-4.2c0-.232.188-.42.42-.42h2.1v5.04H8.4Zm30.66-.42c0 .232-.188.42-.42.42h-25.2v-5.04h25.2c.232 0 .42.188.42.42v4.2ZM18.48 13.86H33.6c1.856 0 3.36-1.504 3.36-3.36V6.3c0-1.856-1.504-3.36-3.36-3.36H18.48c-1.856 0-3.36 1.504-3.36 3.36v4.2c0 1.856 1.504 3.36 3.36 3.36Zm-.42-7.56c0-.232.188-.42.42-.42H33.6c.232 0 .42.188.42.42v4.2c0 .232-.188.42-.42.42H18.48c-.232 0-.42-.188-.42-.42V6.3Z" fill="#fff" fill-rule="evenodd" className="theicon"/><path fill="none" d="M0 0h42v42H0z"/></g></svg>
								</div><br />
								project schedule
							</NavLink>
						</li>
					}
					<li>
						<NavLink to="/account/" className={(navData) => navData.isActive ? classes.active : ''} onClick={navigationHandler}>
							<div className="icn account">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
									<path clip-rule="evenodd" fill="none" d="M0 0h42v42H0z" />
									<path d="M20.9 21c-4.7 0-8.4-3.8-8.4-8.4 0-4.7 3.8-8.4 8.4-8.4s8.4 3.8 8.4 8.4c.1 4.6-3.7 8.4-8.4 8.4m14.2 5.9c-2.2-2.2-4.8-3.8-7.6-4.8 3-2.1 5-5.5 5-9.3.1-6.2-4.9-11.6-11.1-11.8-6.6-.3-12 5-12 11.6 0 3.9 2 7.4 5 9.5-2.8 1-5.4 2.6-7.6 4.8-3.4 3.3-5.4 7.7-5.8 12.4-.1.9.7 1.7 1.6 1.7.8 0 1.5-.6 1.5-1.4.7-8.6 8-15.4 16.8-15.4S37 31 37.7 39.6c.1.8.7 1.4 1.5 1.4.9 0 1.6-.8 1.6-1.7-.3-4.7-2.3-9.1-5.7-12.4" fill-rule="evenodd" clip-rule="evenodd" fill="#e9eaf0" className="theicon" />
								</svg>
							</div><br />
							account
						</NavLink>
					</li>
					<li className="logoutlink">
						<NavLink to="/" onClick={logoutHandler}>
							<div className="icn logout">
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
									<path fill="none" d="M0 0h17v17H0z" />
									<path d="M3.95571 9.05093h11.4061l-2.09343 2.09346.77909.77908L16.667 9.30394a1.13691 1.13691 0 000-1.60785l-2.61953-2.61954-.77909.77909 2.09343 2.09346H3.95571A.55091.55091 0 003.4048 8.5a.55092.55092 0 00.55091.55093zm-2.30295 6.50072h8.78193a1.6546 1.6546 0 001.65274-1.65273v-2.20365h-1.10182v2.20365a.55154.55154 0 01-.55092.55091H1.65274a.55154.55154 0 01-.55092-.55091V3.10108a.55154.55154 0 01.55092-.55091h8.782a.55154.55154 0 01.55092.55091v2.20365h1.10182V3.10108a1.6546 1.6546 0 00-1.65274-1.65273h-8.782A1.6546 1.6546 0 000 3.10108v10.79784a1.65464 1.65464 0 001.65276 1.65273z" fill="#e9eaf0" className="theicon" />
								</svg>
							</div>
							logout
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>

	)
}

export default Navigation;